var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-box"},[_c('div',{staticClass:"flex-item"},[_c('div',{staticClass:"box-item"},[_c('img',{attrs:{"src":_vm.liveDetailInfo.img_url,"alt":""}})]),_c('div',{staticClass:"box-title"},[(_vm.liveDetailInfo.webinar_state == 1)?_c('div',{staticClass:"title-status padlr8"},[_c('img',{attrs:{"src":require("../../../../common/images/live/lives.gif"),"alt":""}}),_c('b',[_vm._v(" 直播 "),(_vm.liveDetailInfo.webinar_type == 5)?_c('span',[_vm._v("| 定时直播")]):_vm._e(),(
              _vm.hasDelayPermission &&
              _vm.liveDetailInfo.no_delay_webinar &&
              _vm.liveDetailInfo.webinar_type != 6
            )?_c('span',[_vm._v(" | 无延迟 ")]):_vm._e(),(_vm.webinarDirector && _vm.liveDetailInfo.is_director === 1)?_c('span',[_vm._v("| 云导播")]):_vm._e(),(_vm.liveDetailInfo.webinar_show_type == 0)?_c('span',[_vm._v("| 竖屏")]):_vm._e()])]):_c('div',{staticClass:"title-status grayColor"},[_c('b',[_vm._v(" "+_vm._s(_vm._f("actionTextTag")(_vm.liveDetailInfo))+" "),(
              _vm.liveDetailInfo.webinar_type != 6 &&
              _vm.hasDelayPermission &&
              _vm.liveDetailInfo.no_delay_webinar
            )?_c('span',[_vm._v(" | 无延迟 ")]):_vm._e(),(_vm.webinarDirector && _vm.liveDetailInfo.is_director === 1)?_c('span',[_vm._v("| 云导播")]):_vm._e(),(_vm.liveDetailInfo.webinar_show_type == 0)?_c('span',[_vm._v("| 竖屏")]):_vm._e()])]),_c('div',{staticClass:"title-text"},[_c('p',[_vm._v(" "+_vm._s(_vm.liveDetailInfo.subject)+" ")])]),_c('div',{staticClass:"box-time"},[_vm._v("直播时间："+_vm._s(_vm.liveDetailInfo.start_time))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }