<!-- 有pdf权限时报名表单导出用户弹窗 -->
<template>
  <vh-dialog
    title="导出数据"
    class="export-user-dialog"
    :visible.sync="dialogVisible"
    @close="closeHandle"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    top="10vh"
    width="480px"
  >
    <vh-form ref="ruleForm" label-width="80px">
      <vh-form-item label="导出格式">
        <vh-radio v-model="mode" label="excel" v-if="showExcel">Excel</vh-radio>
        <vh-radio v-model="mode" label="pdf" v-if="hasDownloadPdf">
          PDF
          <vh-tooltip
            class="item"
            effect="dark"
            content="勾选字段过多，导出效果可能不美观，请按需勾选"
            placement="top"
          >
            <div slot="content">
              1.勾选字段过多，导出效果可能不美观，请按需勾选
              <br />
              2.如果勾选的字段无数据时，导出不展示此字段
            </div>
            <i class="iconfont-v3 saasicon_help_m" style="vertical-align: middle"></i>
          </vh-tooltip>
        </vh-radio>
      </vh-form-item>
      <vh-form-item label="导出字段" v-if="mode === 'pdf'" class="form-item__fields">
        <vh-checkbox-group v-model="checkedFields">
          <vh-checkbox v-for="city in fields" :label="city.key" :key="city.key">
            {{ city.label }}
          </vh-checkbox>
        </vh-checkbox-group>
      </vh-form-item>
    </vh-form>
    <div slot="footer">
      <vh-button size="medium" type="info" plain round @click="closeHandle">取消</vh-button>
      <vh-button size="medium" type="primary" round v-preventReClick @click="handleSubmit">
        导出
      </vh-button>
    </div>
  </vh-dialog>
</template>
<script>
  import { sessionOrLocal } from '@/utils/utils';
  import to from 'await-to-js';

  export default {
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      hasDownloadPdf: {
        type: Boolean,
        default: false
      },
      webinarId: {
        type: [Number, String],
        default: 0
      },
      // 1 中文，2：英文, 默认为 1
      languageType: {
        type: [Number, String],
        default: 1
      },
      // webinar ，subject,  专题还是活动
      sourceType: {
        type: [String],
        default: 'webinar'
      },
      query: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        mode: 'excel', // 导出格式 excel、 pdf
        checkedFields: [],
        fields: [],

        parentId: 0 // 父账户id, >0则表示当前是子账号
      };
    },
    watch: {
      dialogVisible(val) {
        if (val && !this.showExcel) {
          this.mode = 'pdf';
        }
      }
    },
    computed: {
      dialogVisible: {
        get() {
          return this.visible;
        },
        set(val) {
          this.$emit('update:visible', val);
        }
      },
      showExcel() {
        // 主账号一定有excel导出权限， 子账号需要判断开关
        if (
          this.parentId == 0 ||
          (this.parentId > 0 &&
            this.childPermission?.permission_data &&
            this.childPermission?.permission_data_download)
        ) {
          return true;
        }
        return false;
      },
      childPermission: function () {
        return sessionOrLocal.get('SAAS_V3_SON_PS')
          ? JSON.parse(sessionOrLocal.get('SAAS_V3_SON_PS'))
          : {};
      }
    },
    methods: {
      closeHandle() {
        this.dialogVisible = false;
        this.$emit('close');
      },
      async getExportField() {
        let params = {};
        if (this.sourceType === 'subject') {
          params.is_subject = 1;
        }
        const [err, res] = await to(this.$fetch('getExportField', params));
        if (err || !res || res.code != 200) {
          this.$message.error(err.msg || res?.msg || '请求失败');
          return;
        }
        this.fields = res.data;
        this.checkedFields = this.fields.filter(item => item.checked).map(item => item.key);
      },
      async handleSubmit() {
        let params = {
          language_type: this.languageType,
          export_type: this.mode
        };
        if (this.sourceType === 'webinar') {
          params.webinar_id = this.webinarId;
        } else if (this.sourceType === 'subject') {
          params.subject_id = this.webinarId;
        }
        if (this.query) {
          params.type = this.query.type;
          params.keyword = this.query.keyword;
          params.start_time = this.query.start_time;
          params.end_time = this.query.end_time;
          params.audit_status = this.query.audit_status;
          if (this.query.is_enter !== '') {
            params.is_enter = this.query.is_enter;
          }
        }
        if (this.mode === 'pdf') {
          params.export_fields = this.checkedFields;
        }
        console.log('params:', params);
        const [err, res] = await to(this.$fetch('exportForm', params));
        if (err || !res || res.code != 200) {
          this.$message.error(err.msg || res?.msg || '请求失败');
          return;
        }
        this.$vhMessage.info(
          `导出申请成功，${
            this.$store.state.permissionValue.custom_download_toast || '请去下载中心下载'
          }`
        );
        this.$EventBus.$emit('saas_vs_download_change');
        this.closeHandle();
      },
      // 初始化权限
      initPermission() {
        // 能打开该对话框，说明一定有pdf导出权限
        let userInfo = sessionOrLocal.get('userInfo');
        if (userInfo) {
          userInfo = JSON.parse(userInfo);
          this.parentId = userInfo.parent_id;
        }
      }
    },
    mounted() {
      this.getExportField();

      this.initPermission();
    }
  };
</script>
<style lang="less" scoped>
  .export-user-dialog {
    /deep/ .vh-checkbox {
      width: 120px;
    }

    .form-item__fields {
      /deep/ .vh-form-item__content {
        line-height: 26px;
      }
    }
  }
</style>
